import React from "react"

import Layout from "../../../components/layout"
import Seo from "../../../components/Seo"
import Spacer from "../../../components/Spacer"
import {
  PrimaryHero,
  HeroCopyBlock
} from "../../../components/Heros"
import {
  DualImageBlock
} from "../../../components/Grid"

import chairsImg from "./images/chairs.png"
import mugsImg from "./images/mugs.png"
import officeImg from "./images/office.png"
import officeDesksImg from "./images/officedesks.png"
import msgIcon from "./images/default.svg"
import gymImg from "./images/gym.png"
import tramImg from "./images/tram.png"

import styles from "./styles.module.scss"

const SortedHouse = () => (
  <Layout JobSectionLight>
    <Seo title="Sorted | The sorted house" keywords={[`Sorted`, `House`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock title={["Feel at home."]}>
        <p>We believe that the right environment stimulates creativity and innovation. Every detail of our Sorted Tech House has been designed to encourage creative thinking and collaborative working. That’s what got us named in the top three ‘most inspired’ workspaces in the North.</p>
      </HeroCopyBlock>
    </PrimaryHero>
    <Spacer size="medium" sides="topbottom">
      <div class="container-ultrathin">
        <h2 className="text-pink text-center">Floor to ceiling.</h2>
        <p className="text-center">The lights, the colours, the materials, the space, the kit and the layout— from the one person work pod and the sliding whiteboard walls, to the yoga studio and the cinema room. Absolutely everything in our environment has been crafted to keep our team happy, healthy and productive.</p>
      </div>
    </Spacer>

    <div class="container-large">
      <div className={styles.officeImgBlock}>
        <div className={styles.left}><img src={chairsImg} alt="sorted office chair"/></div>
        <div className={styles.topright}><img src={officeImg} alt="sorted office layout"/></div>
        <div className={styles.bottomright}><img src={mugsImg} alt="sorted office mugs"/></div>
      </div>
    </div>

    <div className={styles.genericPad}>
      <DualImageBlock img={officeDesksImg} theme="grey" maxHeight={700}>
        <h3>Other touches include</h3>
        <div className={styles.bulletSection}>
          <div className={styles.bulletItem}>
            <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
            <p>Subsidised office salon for hair and beauty</p>
          </div>
          <div className={styles.bulletItem}>
            <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
            <p>Beer pump and fully stocked beer fridge</p>
          </div>
          <div className={styles.bulletItem}>
            <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
            <p>Plants and greenery</p>
          </div>
          <div className={styles.bulletItem}>
            <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
            <p>Open spaces and standing desks</p>
          </div>
          <div className={styles.bulletItem}>
            <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
            <p>Exercise facilities</p>
          </div>
          <div className={styles.bulletItem}>
            <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
            <p>Pool table, ping pong, board games and library</p>
          </div>
        </div>
      </DualImageBlock>
    </div>

    <div className={styles.genericPad}>
      <div className="container-large-fluid">
        <DualImageBlock reverse img={gymImg} theme="purple" maxHeight={450}>
          <div className={styles.designedByBlock}>
            <h3>Designed by our people.</h3>
            <p>Every element of our space was influenced and inspired by our people. Full consultation with our teams, and expert designers, ensured we created a space that was packed with useful, practical and fun details.</p>
          </div>
        </DualImageBlock>
      </div>
    </div>


    <DualImageBlock reverse img={tramImg} theme="pink" maxHeight={450}>
      <h3>Trains, trams and automobiles.</h3>
      <p>With Albert Square on our doorstep, a short walk to all train stations and secure bike storage on site; we’re easy to find and easy to get to.</p>
    </DualImageBlock>

  </Layout>
)

export default SortedHouse
